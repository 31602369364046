<template>
	<div id="app">
		<router-view/>
	</div>
</template>

<script>
export default {
	name: 'App'
}
</script>

<style>
body, html {
	padding: 0;
	margin: 0;
}
</style>
